.table {
  position: relative;
  display: flex;
  overflow: auto;
  flex-direction: column;
  flex-grow: 1;
}

.thead {
  font-size: 12px;
  height: 40px;
  display: flex;
  min-width: 100%;
  width: min-content;
  border-bottom: 1px solid var(--divider);
  position: sticky;
  top: 0;
  background-color: var(--bg);
  z-index: 1;
  flex-shrink: 0;

  &.fixed {
    grid-area: fixedHeader;
    left: 0;
    z-index: 1;
  }
}

.tbody {
  font-size: 14px;
  display: flex;
  flex-direction: column;
  background-color: var(--bg);
  grid-area: body;

  &.fixed {
    grid-area: fixedBody;
    position: sticky;
    left: 0;
  }
}

.autoRows {
  .tbody {
    .row {
      height: auto;
    }
  }
}

.row {
  display: flex;
  min-width: 100%;
  width: max-content;
  justify-content: flex-start;
  height: 43px;
  background-color: var(--bg);
  border-bottom: 1px solid var(--divider);

  &:nth-child(even) {
    background-color: var(--tableBgAlt);
  }

  &.selected, &:hover {
    background-color: var(--rowHover) !important;
  }
}

.cell {
  padding: 12px;
  flex-shrink: 0;
  width: 120px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  background-color: inherit;
}

.headerSticky {
  display: flex;
  position: sticky;
  flex-shrink: 0;
  left: 0;
  background-color: inherit;
  z-index: 1;
}

.rowSticky {
  display: flex;
  position: sticky;
  flex-shrink: 0;
  left: 0;
  background-color: inherit;
}

.th {
  padding: 0 12px;
  height: 100%;
  align-items: center;
  flex-shrink: 0;
  width: 120px;
  display: flex;
  position: relative;
  justify-content: space-between;
  color: var(--textSecondary);
  height: 100%;

  .iconBox {
    display: flex;
    align-items: center;
  }

  .text {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow-x: hidden;
  }

  .chevron {
    fill: var(--textPrimary);
    height: 16px;
    margin-left: 6px;

    &.inactive {
      display: none;
    }
  }

  .iconBox {
    .icon {
      fill: var(--themePrimary);
    }
    .index {
      line-height: 16px;
      font-size: 12px;
      color: var(--themePrimary);
    }
  }
}

.icon {
  &.flip {
    transform: rotate(180deg);
  }
}
.iconBtn {
  display: flex;
  border: none;
  outline: none;
  background-color: transparent;
  cursor: pointer;
  align-items: center;
  padding: 0 24px;
  height: 36px;
  width: 100%;
  fill: var(--textPrimary);

  &:hover {
    background-color: var(--bgDisabled);
  }

  .icon {
    margin-right: 8px;
  }

  &.btnActive {
    display: flex;

    .icon {
      fill: var(--themePrimary);
    }
  }
}

.resizeWrapper {
  position: relative;
  &.noResize {
    &:before {
      content: " ";
      width: 1px;
      margin: 0 3px;
      background-color: var(--divider);
      display: block;
      position: absolute;
      right: 0;
      top: 8px;
      height: 24px;
      cursor: col-resize;
    }
  }
}

:global {
  .react-resizable-handle {
    content: " ";
    width: 1px;
    margin: 0 3px;
    background-color: var(--divider);
    display: block;
    position: absolute;
    right: 0;
    top: 8px;
    height: 24px;
    cursor: col-resize;

    &:hover {
      box-shadow: 1px 1px gray;
    }
  }
}

.header {
  padding: 10px;
  flex: 0 0 200px;
}

.menuWrapper {
  display: flex;
  flex-direction: column;
  box-shadow: var(--shadow);
  min-width: 202px;
  background-color: var(--bg);
  border-radius: 6px;
  align-items: stretch;
  .menuDivider {
    width: 100%;
    height: 1px;
    background-color: var(--divider);
  }
}
